
/* celular */
@media (max-width:700px) {
  .header-nav .title {
    font-size: 2.5rem;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
    margin-top: 15px !important;
  }

}
/* widescreen */
@media (min-width:700px) {
  .header-nav .title {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  }
}

.user-name {
  position: absolute;
  top: -3px;
  left: 5px;
  font-size: small;
}